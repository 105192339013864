.e-medical-radio-str {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .ant-radio-group {
        display: flex;
        justify-content: flex-start;
    }
}
@root-entry-name: default;