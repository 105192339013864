@import '../../style/vars.less';

.e-medical-collapse-block-headertext {
    display: flex;
    align-items: center;
    padding-right: 24px;
    justify-content: space-between;

    .title {
        font-weight: bold;
    }

    .desc {
        color: @secondary-text-color;
    }

    .error {
        font-size: @font-size-sm;
        color: #ff2d2d;
    }
}

.e-medical-collapse-block {
    width: 100%;

    +.e-medical-collapse-block {
        padding-top: @margin-base;
    }

    >.ant-collapse {
        border: 1px solid @secondary-color;
        border-radius: 12px;
        background-color: @secondary-color;

        >.ant-collapse-item>.ant-collapse-header .ant-collapse-header-text {
            font-size: @font-size-md;
            color: @primary-text-color;
        }

        >.ant-collapse-item {
            border-radius: 12px;
            border: 0;

            >.ant-collapse-content {
                border-top: 0;
                border-radius: 0 0 12px 12px !important;

                >.ant-collapse-content-box {
                    padding: 20px;
                    border-radius: 0 0 12px 12px;
                }
            }
        }
    }

    .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
        padding: 12px 20px;
    }
}

.e-medical-collapse-block-inline {
    >.ant-collapse {
        border: 1px solid transparent;
        border-radius: 0;
        background: #f5f5f5;

        .ant-collapse-content {
            border-top: 1px;
            border-radius: 0 0 0 0 !important;
            background: #fafafa;

            >.ant-collapse-content-box {
                padding: 20px;
                border-radius: 0;
            }
        }
    }

    .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
        padding: 12px 20px;
        background: #f5f5f5 !important;
    }

    .e-medical-collapse-block-headertext {
        .title {
            font-weight: normal;
        }
    }
}
@root-entry-name: default;