@import '../../style/vars.less';

.vsf-layout-header-main {

    .logo {
        width: 356px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 24px;

        .logo-img {
            width: 154px;
            height: 30px;
            position: relative;
        }

        .ant-image {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }

        .ant-image-img {
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

.vsf-layout-header-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    >div {
        margin: 0 6px;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .user-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .avatar-img {
            width: 36px;
            height: 35px;
            position: relative;
        }

        .label {
            margin-left: 4px;
            white-space: nowrap;
        }
    }
}

.user-dropdown-item {
    cursor: pointer;

    &:hover {
        color: @primary-color;
    }
}
@root-entry-name: default;