@import '../../style/vars.less';

.e-medical-anchor-block {
    width: 56px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .top {
        width: 56px;
    }

    .content {
        width: 100%;
        height: 100%;
        background-color: #f0f2f5;
        display: flex;
        justify-content: center;
    }

    .ant-anchor-ink::before {
        width: 0;
    }

    .ant-anchor-link {
        padding: 0;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(255, 96, 163, 0.3);
        background-color: #fff;
        border-radius: 8px;
        margin-top: @margin-base;
    }

    .ant-anchor-ink-ball {
        display: none !important;
    }
}
@root-entry-name: default;