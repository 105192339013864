.e-medical-other-advice {
    .first {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        margin-bottom: 12px;

        >span {
            +span {
                margin-left: 8px;
            }
        }

        .label {
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-weight: bold;
        }

        .ant-input-number {
            width: 80px !important;
        }
    }

    .second {
        position: relative;

        span {
            position: absolute;
            top: 0;
            z-index: 99;
            left: 0;
            height: 32px;
            line-height: 32px;
            padding: 0 11px;
        }

        textarea {
            text-indent: 690px;
        }
    }
}
@root-entry-name: default;