@import "../../style/vars.less";

.e-medical-left-block {
    width: 100%;
    height: 100%;
    position: relative;

    >.content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        >div {
            width: 100%;
        }

        .left-gravida-info {
            height: 194px;
            min-height: 194px;
            max-height: 194px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: column;
            background: linear-gradient(180deg, #FFEDF5 0%, rgba(255, 237, 245, 0.00) 100%);
            position: relative;

            .title {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                position: absolute;
                left: 25px;
                top: 20px;


                .word {
                    height: 32px;
                    padding: 0 16px;
                    background-color: #fff;
                    color: @primary-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0 30px 30px 0;

                    .anticon {
                        color: #666;
                        margin-left: 12px;
                    }
                }

                .bookbg {
                    width: 40px;
                    height: 40px;
                }
            }

            .info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                // position: relative;

                &::after {
                    content: "";
                    width: 300px;
                    height: 1px;
                    background: rgba(0, 0, 0, 0.06);
                    position: absolute;
                    bottom: 0;
                    left: 20x;
                }

                .name {
                    font-size: 20px;
                    font-weight: bold;
                    color: #333;
                }

                .first {
                    margin: 12px 0 10px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    >span {
                        margin-left: 4px;
                        font-size: @font-size-md;
                        color: #666;
                    }

                    .ant-image {
                        width: 16px;
                        height: 13px;
                    }

                    .recordbg {
                        width: 16px;
                        height: 13px;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }

        >.main {
            height: 100%;
            padding: 0 20px;
            overflow-y: auto;
            overflow-x: hidden;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .main-block {
                width: 100%;
            }

            .tag-block {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                height: 30px;
                margin: 10px 0;
                cursor: pointer;
                padding: 4px 0;

                .tag-label {
                    font-size: 16px;
                    color: #333;
                    white-space: nowrap;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    .title {
                        margin-right: 8px;
                    }

                    .text-content {
                        white-space: nowrap;
                    }
                }

                .anticon {
                    color: #666;
                }
            }

            .physical-info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin: 12px 0;

                .physical-info-block {
                    width: 94px;
                    border-radius: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #FFF7FA;
                    flex-direction: column;
                }

                .ant-image {
                    width: 50px;
                    height: 50px;
                    margin: 16px 0;
                }

                .value {
                    margin-bottom: 16px;
                    min-height: 22px;
                }
            }

            .gravida-info {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;

                .gravida-info-block {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    border-radius: 8px;
                    padding: 16px 10px;
                    width: 145px;
                    margin-bottom: 10px;
                    overflow: hidden;
                    background-color: #FFF7FA;

                    .label {
                        color: #666;
                        font-size: @font-size-sm;
                        position: relative;

                        &::before {
                            content: "";
                            width: 8px;
                            height: 8px;
                            background-color: @primary-color;
                            position: absolute;
                            top: 7px;
                            left: -16px;
                            transform: rotate(45deg);
                        }
                    }

                    .value {
                        width: 100%;
                        height: 32px;
                        margin-top: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding-left: 10px;
                        font-size: @font-size-md;
                        color: #333;
                    }

                    .edit {
                        width: 100%;
                        height: 32px;
                        margin-top: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: @font-size-md;
                        color: #333;
                    }
                }

                .gravida-info-block-edit {
                    .value {
                        background-color: #fff;
                        cursor: pointer;
                    }
                }
            }

            .footer-block {
                margin: 20px -20px 0;

                .content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }


                .child {
                    width: 108px;
                    top: 40px;
                    position: relative;
                    z-index: 99;
                }

                .footer {
                    width: 340px;
                }
            }
        }
    }
}
@root-entry-name: default;