.e-medical-assistant {
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    .e-medical-assistant-outline {
        border: 1px dashed #C2C9DF;
        padding: 10px;
    }

    .ant-radio-group {
        display: flex;
        height: 32px;
        align-items: center;

        .ant-radio-wrapper {
            white-space: nowrap;
        }
    }

    .e-medical-assistant-formitem-block {
        display: flex;
        margin: 2px 0;

        +.e-medical-assistant-formitem-block {
            margin-top: 8px
        }

        >div {
            display: flex;
            align-items: center;

            +div {
                margin-left: 16px;
            }

            .label {
                white-space: nowrap;
                width: 100px;
            }
        }

        .ant-input {
            width: 160px;
        }

        .ant-select {
            width: 160px;
        }

        .slash {
            margin: 0 12px;
        }

        .formitem-nowrap {
            display: flex;
            justify-content: flex-start;
            white-space: nowrap;
            align-items: center;
        }

        .formitem-block-middle {
            .ant-input {
                width: 104px;
            }

            .molecule {
                width: 34px;
            }

            .special {
                width: 220px;
            }
        }

        .formitem-full {
            width: 100%;
            display: flex;
            justify-content: flex-start;

            .formitem-nowrap {
                width: 100%;
            }

            .ant-select {
                flex: 1;
                width: auto;

                +.ant-select {
                    margin-left: 8px;
                }
            }
        }
    }
}

.e-medical-assistant-vertical {
    flex-direction: column;
}
@root-entry-name: default;