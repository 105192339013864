.e-medical-gestational-week {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .ant-input-number-group-wrapper {
        +.ant-input-number-group-wrapper {
            margin-left: 8px;
        }
    }
}
@root-entry-name: default;