.ant-layout {
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: relative;
}

.ant-picker {
  width: 100%;
}

.ant-input-number {
  width: 100% !important;
}

.ant-form-item-label>label {
  white-space: normal;
}

.ant-input-number-group-wrapper {
  width: 100%;
}

.e-medical-form-outline {
  border: 1px dashed #C2C9DF;
  padding: 24px 12px 0 12px !important;
}

.no-error {
  margin-bottom: 4px !important;
}

.ant-space-item {
  white-space: nowrap;

  .ant-btn-link {
    padding: 0 !important;
  }
}

.ant-form-horizontal .ant-form-item-label {
  overflow: visible;
}

.ant-radio-checkbox-border {
  border: 1px dashed #C2C9DF;
  padding: 4px 6px !important;
  width: 100%;
}
@root-entry-name: default;