    .e-medical-record-footer-btn {
        cursor: pointer;
        color: #FF60A3;
    }

    .e-medical-record-footer-table {
        .ant-table-cell {
            vertical-align: bottom;
        }

        .cell-block {
            + .cell-block {
                margin-top: 16px;
            }
        }
    }
@root-entry-name: default;