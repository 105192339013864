@import '../style/vars.less';

.@{layout} {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .@{layout}-sider {
    width: 340px;
  }
  .vsf-layout-sider-left {
    width: 24% !important;
    max-width: 18% !important;
    min-width: 18% !important;
  }

  .@{layout}-header {
    height: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 1px solid #d9d9d9;
    // box-shadow: 1px 1px 5px #d9d9d9;

    .@{layout}-header-main {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      // border-bottom: 1px solid #0000000F;

      .logo {
        width: 360px;
        text-indent: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        color: #333;

        .ant-input {
          width: 150px;
          margin-left: 4px;
        }
      }

      .@{layout}-header-menu {
        height: 48px;
        min-height: 48px;
        border-bottom: 0;

        .ant-menu-item {
          width: 120px;
          text-align: center;
          padding: 0;
        }

        > .ant-menu-item:hover,
        > .ant-menu-submenu:hover,
        > .ant-menu-item-active,
        > .ant-menu-submenu-active,
        > .ant-menu-item-open,
        > .ant-menu-submenu-open,
        > .ant-menu-item-selected,
        > .ant-menu-submenu-selected {
          // color: @primary-color  !important;
          background-color: @secondary-color;

          &::after {
            left: 0;
            right: 0;
          }
        }
      }
    }

    .@{layout}-header-right {
      padding: 0 20px;
    }
  }

  .@{layout}-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .@{layout}-main {
    flex: 1;
    // padding: @padding-base;
    background-color: rgb(243, 243, 251);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: auto;

    .main-block {
      padding: @padding-base;
      width: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: auto;

      .router {
        width: 100%;
        height: 100%;
        // background-color: #ffffff;
        // box-shadow: 0px 8px 16px 0px #DDDDDD;
        // border-radius: 14px;
      }
    }

    .@{layout}-footer {
      width: 100%;
      background-color: #ffffff;
      height: 80px;
      min-height: 80px;
      display: flex;
      align-items: center;
      overflow-x: auto;
      padding: 0 40px;
      // border-bottom: 1px solid #d9d9d9;
      // box-shadow: 1px 1px 5px #d9d9d9;

      .@{layout}-footer-main {
        display: flex;
        justify-content: flex-start;
        position: relative;

        &::after {
          position: absolute;
          content: '';
          width: calc(~'100% - 10px');
          left: 5px;
          top: 16px;
          height: 1px;
          background-color: #ff87b5;
        }

        .item {
          width: 120px;
          text-align: center;
          height: 32px;
          color: #666666;
          background: #f0f2f5;
          border: 2px solid transparent;
          border-radius: @border-radius-base;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 2;
          cursor: pointer;

          // +.item {
          //     margin-left: 120px;
          // }
        }

        .active {
          color: #ffffff;
          background-color: #ff87b5;
          border: 2px solid #ffe3f4;
        }

        .ant-badge {
          z-index: 2;

          + .ant-badge {
            margin-left: 120px;
          }
        }
      }
    }
  }
}

.e-medical-footer-popover {
  .ant-popover-title {
    height: 34px;
    position: relative;
    padding: 5px 25px 4px;

    &:before {
      content: '';
      width: 3px;
      height: 14px;
      background-color: @primary-color;
      position: absolute;
      left: 16px;
      top: 9px;
      border-radius: 3px;
    }
  }

  .ant-popover-inner-content {
    padding: 16px !important;
  }

  .ant-pro-card-body {
    padding: 0;
  }

  .overtime {
    color: #ff3b30;
  }
}

@root-entry-name: default;