.e-medical-draw {
    .ant-pro-card-body {
        padding: 0;
    }

    .block {
        +.block {
            margin-top: 12px;
        }
    }

    .container {
        padding: 12px 12px;
        margin: 12px 0;
        background-color: #FFFAFD;

        >.inline-container {
            +.inline-container {
                margin-top: 24px;
            }
        }
    }

    .title {
        color: #333;
        font-size: 16px;
        font-weight: bold;
    }

    .word-thin {
        font-size: 14px;
        color: #666;
        font-weight: 200;
    }

    .word-normal {
        font-size: 14px;
        color: #666;
        font-weight: 400;
    }

    .check-block {
        span {
            white-space: pre;
            padding: 0 2px;
        }
    }

    .second-title {
        color: #333;
        font-size: 16px;
        position: relative;
        padding-left: 8px;
        height: 22px;
        line-height: 22px;
        font-weight: bold;

        &::after {
            content: "";
            width: 4px;
            height: 16px;
            top: 3px;
            background: #FF60A3;
            position: absolute;
            left: 0;
            border-radius: 12px;
        }
    }

    .inline-block {
        margin: 8px 0;
        margin-bottom: 16px;

        .content {
            background-color: #FFEFF8;
            margin-bottom: 10px;
            &__title {
              padding: 10px 0 0 20px;
              font-weight: bold;
              font-size: 16px;
            }
        }

        .word-content {
            padding: 16px;
            &__title {
              font-weight: bold;
              font-size: 16px;
              margin-bottom: 10px;
            }
            .chinese-item {
              margin-bottom: 10px;
                +.chinese-item {
                    margin-top: 30px;
                }
            }

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .label {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

                >div {
                    span {
                        margin-right: 8px;
                    }
                }

                .bold {
                    font-weight: bold;
                    font-size: 16px;
                    color: #333;
                }

                .desc {
                    font-size: 14px;
                    color: #666;
                }

                .ps {
                    font-size: 14px;
                    margin-right: 100px;
                    color: #666;
                }
            }

            .section {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                position: relative;

                >div {
                    width: 50%;
                    margin-top: 4px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

                .name {
                    display: inline-block;
                    white-space: nowrap;
                    max-width: 200px;
                    min-width: 200px;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .inline-block-div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.e-draw-medical-table {
    .ant-table-thead {
        background-color: #FFEFF8 !important;

        th {
            background-color: #FFEFF8 !important;
        }
    }
}

@root-entry-name: default;