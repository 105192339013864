.e-epidemic-modal {
    .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .ant-checkbox-group-item {
            padding: 8px;
        }
    }
}
@root-entry-name: default;