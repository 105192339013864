.anteatalPrint-container {
  width: 100%;
  box-sizing: border-box;
  height: 200px;
  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
  }
  .content {
    padding: 10px;
    width: 100%;
    page-break-after: always;
    box-sizing: border-box;
    table {
      margin: 0;
      width: 100%;
    }
    tbody {
      .unit {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        writing-mode: horizontal-tb;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .unit > div {
        writing-mode: horizontal-tb !important;
      }
      .unitrl > div {
        writing-mode: vertical-rl !important;
      }
    }

    th {
      border: 1px solid #333;
      width: 30px;
      height: 150px;
      writing-mode: vertical-rl;
      .unit {
        height: 100%;
        width: 100%;
        writing-mode: horizontal-tb;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .unit div:first-child {
        writing-mode: vertical-rl;
      }
    }
    .bigTh {
      width: 100px;
      text-align: center;
      box-sizing: border-box;
      > div {
        width: 98%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@root-entry-name: default;