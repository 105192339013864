@import '../../style/vars.less';

.form-error-tips {
    background-color: #FFFBE6;
    border: 1px solid #FFE58F;
    padding: 16px;

    .header {
        display: flex;
        justify-content: space-between;
        padding-left: 48px;
        position: relative;

        .left {
            font-size: 16px;
            font-weight: bold;

            .anticon {
                position: absolute;
                left: 0;
                top: 0;
                font-size: 24px;
                color: #FFE58F;
            }
        }

        .right {
            .anticon {
                cursor: pointer;
                font-size: 16px;
            }
        }
    }

    .error-content {
        padding: 0 24px 0 48px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
    }
}

.e-medical-form-error-block {
    display: flex;
    align-items: center;

    .ant-anchor-error-link-title {
        color: @primary-color;
        margin-left: 8px;
        margin-bottom: 0;
        cursor: pointer;
        white-space: nowrap;
    }
}
@root-entry-name: default;