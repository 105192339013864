@import '../style/vars.less';

.e-medical-main {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;

  .e-medical-page-block {
    width: calc(~'100% - 8px');
    + .e-medical-page-block {
      margin-left: @margin-base;
    }
  }
}

.e-medical-main-nurse {
  .e-medical-page-block {
    width: 100%;

    + .e-medical-page-block {
      margin-left: 0;
    }
  }
}

.e-medical-diagnose {
  margin-top: 20px;
}

.e-medical-form-submit {
  .ant-btn {
    + .ant-btn {
      margin-left: 8px;
    }
  }
}

.e-medical-print-container {
  display: none;
}
#nurse-medical-anchor-container {
  .ant-form-item {
    margin-bottom: 10px !important;
  }
}

@media Print {
  .e-medical-print-container {
    display: inline;
  }
}

@root-entry-name: default;