@import '../../style/vars.less';

.e-medical-highrisk-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #FFF7FA;
    width: 100%;
    border-radius: 12px;
    padding: @padding-base;

    >div {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: @font-size-md;
        color: #333;
        cursor: pointer;

        +div {
            margin-top: 12px;
        }

        >span {
            margin-right: 6px;
        }

        >div {
            min-width: 120px;
            flex: 1;
            height: 16px;
            border-radius: 20px;
            text-align: center;
            position: relative;
            line-height: 16px;
            color: #fff;
            font-size: @font-size-sm;

            .date {
                position: relative;
                z-index: 2;
                font-size: 12px;
            }

            .date-gray {
                color: #999;
            }

            .bar {
                z-index: 1;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                border-radius: 20px;

                span {
                    flex: 1;

                    &:first-child {
                        border-radius: 20px 0 0 20px;
                    }

                    &:last-child {
                        border-radius: 0 20px 20px 0;
                    }
                }
            }
        }
    }
}

.e-medical-highrisk-check {
    width: 100%;
    padding: 8px 24px 24px 24px;
    position: relative;
    display: flex;
    flex-direction: row;
    min-height: 550px;
    max-height: 550px;

    .e-medical-highrisk-item {
        flex: 1;
        max-height: 60vh;

        .title {
            height: 32px;
            width: 100%;
            text-align: center;
            line-height: 32px;
        }

        .checkitems {
            padding: @padding-base;
            border: 1px solid #97979726;
            border-top: 0;
            height: calc(~"100% - 32px");
            overflow-y: auto;

            .ant-checkbox-group {
                display: flex;
                flex-direction: column;

                .ant-checkbox-group-item {
                    +.ant-checkbox-group-item {
                        margin-top: @margin-base;
                    }
                }
            }
        }

        +.e-medical-highrisk-item {
            .checkitems {
                border-left: 0;
            }
        }
    }
}
@root-entry-name: default;