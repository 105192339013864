@import '../../style/vars.less';

.e-medical-record-modal {
  .ant-modal-body {
    padding: 0;
  }

  .ant-tabs-nav-list {
    margin-left: 24px;
  }
}

.record-info-main-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 12px 0;

  .name {
    font-size: @font-size-lg;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;

    .bg {
      width: 42px;
      z-index: 1;
    }

    .word {
      height: 38px;
      z-index: 10;
      color: #fff;
      position: relative;
      white-space: nowrap;
      background-color: @primary-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: @font-size-lg;
      padding: 0 @padding-1;
    }
  }

  .idnum {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 26px;

    > span {
      white-space: nowrap;
      overflow: hidden;
    }

    > .ant-image {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .recordbg {
      width: 16px;
      margin-right: 4px;
    }
  }
}

.e-medical-record-main {
  > .container {
    background-color: #fff;
    border-radius: 14px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;

    .left {
      height: 100%;
      width: 227px;
      border-radius: 14px 0 0 14px;

      .ant-menu-root {
        height: 100%;
        padding-top: 24px;
        border-radius: 14px 0 0 14px;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .ant-menu-title-content {
        font-size: 16px !important;
      }
    }

    .right {
      height: 100%;
      flex: 1;
      border-radius: 0 14px 14px 0;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .header {
        width: 100%;
        padding: @padding-base 2 * @padding-base;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);

        .title {
          margin-top: @margin-base;
          font-size: 20px;
          color: #000000;
          display: flex;
          justify-content: space-between;
        }
      }

      .right-content {
        flex: 1;
        overflow-y: hidden;
        overflow-x: hidden;
        width: 100%;
        height: 100%;

        > div {
          width: 100%;
          height: 100%;
          padding: 24px;
        }
      }
    }
  }
}

.e-medical-record-detail-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .ant-tabs {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .ant-tabs-nav-list {
      margin-left: 32px;
    }

    .ant-tabs-content {
      width: 100%;
      height: 100%;
    }
  }

  .detail-content {
    height: 100%;
    flex: 1;
    width: 100%;

    .ant-pro-card {
      padding: 0 !important;
    }

    .ant-pro-card-body {
      padding: 0 !important;
    }

    .ant-pro-table {
      padding: 0 !important;
    }
  }
}

.e-medical-record-detail-footer {
  width: 100%;
  height: 52px;
  min-height: 52px;
  max-height: 52px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 12px;

  > .ant-btn {
    margin-right: 12px;
  }
}

.e-medical-create-record-modal {
  .e-medical-create-record-ask-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .ant-image-img {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
}

// .record-container {
//   // height: ;
// //   max-height: 350px;
//   background-color: red;
// }

.e-medical-record-modal-tab {
  .ant-tabs-content-holder {
    overflow-y: auto;
    height: 350px;
  }
}

@root-entry-name: default;