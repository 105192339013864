.e-medical-import-modal {
    .ant-modal-body {
        padding: 0 16px;

        .container {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-items: center;
            height: 592px;
            max-width: 100%;
            min-width: 100%;
            width: 100%;

            .left {
                width: 346px;
                max-width: 346px;
                min-width: 346px;
                height: 100%;
                border-right: 1px solid rgba(0, 0, 0, 0.06);

                .ant-tabs {
                    width: 100%;
                    height: 100%;
                }

                .ant-tabs-content {
                    height: 100%;
                }

                .ant-tabs-tabpane {
                    height: 100%;
                }

                .ant-tabs-nav-list {
                    width: 100%;
                }

                .ant-tabs-tab {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    font-size: 16px;
                }

                .ant-tabs-nav-more {
                    display: none;
                }

                .ant-pro-table {
                    height: 100%;

                    .active {
                        background: rgba(255, 96, 163, 0.15) !important;
                    }
                }

                .filter {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 12px;
                }
            }

            .right {
                flex: 1;
                width: 0;
                height: 100%;
                padding: 16px 8px 16px 24px;

                .header {
                    height: 98px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .title {
                        font-size: 20px;
                        color: rgba(0, 0, 0, 0.85);
                        margin-bottom: 16px;
                    }

                    .desc {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        >div {
                            font-size: 14px;
                            color: rgba(0, 0, 0, 0.45);

                            span {
                                +span {
                                    margin-left: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .ant-pro-card-body {
            padding: 0 !important;
        }
    }
}

.e-medical-import-modal-block {
    .title {
        font-size: 16px;
        color: #333;
        margin-bottom: 10px;
    }

    .detail {
        background: rgba(255, 239, 248, 0.5);
        font-size: 14px;
        color: #3D3D3D;
        width: 100%;
        height: 175px;
        overflow-y: auto;
        border-radius: 4px;
        padding: 12px 16px;
    }
}
@root-entry-name: default;