@import '../../style/vars.less';

@indexSize: 24px;
@addSize: 32px;

.e-medical-block {
  border: 1px solid #f0f2f5;
  border-radius: 12px;
}

.e-medical-data-entering-dropdown {
  max-height: 30vh;

  .ant-dropdown-menu {
    max-height: 30vh;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.e-medical-data-entering {
  user-select: none;

  .e-medical-data-entering-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    background-color: @secondary-color;
    position: relative;
    border-radius: 3 * @border-radius-base 3 * @border-radius-base 0 0;
    padding: 8px 16px;

    .title {
      color: @primary-text-color;
      font-size: @font-size-md;
      // font-weight: 600;
      white-space: nowrap;
      margin-right: 12px;

      &::after {
        content: ':';
        margin-left: 3px;
      }
    }

    .add {
      width: @addSize;
      height: @addSize;
      border: 1px dashed #00000026;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: @border-radius-base;
      margin-top: @margin-2;
      background-color: #ffffff;
      cursor: pointer;
    }

    .data-entering-item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: @margin-2 0 0 0;

      > .title {
        color: @secondary-text-color;
        margin: 0 @margin-2 0 @margin-base;
      }
    }

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border-radius: @border-radius-base;
      height: 32px;
      font-size: @font-size-md;
      font-weight: 500;
      margin: 0 @margin-base 0 @margin-2;

      .item-label {
        white-space: nowrap;
      }

      .item-value {
        width: 38px;
        padding: 0;
        text-align: center;
        border-bottom: 1px solid #969696;
        border-radius: 0;
        font-weight: 500;
        user-select: auto;
      }

      .item-checked {
        margin-left: @margin-2;

        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #1677ff;
          border-color: #1677ff;
        }
      }

      .anticon {
        color: #7e89a4;
        cursor: pointer;
        margin-right: @margin-2;
      }
    }

    .item-normal {
      font-weight: 400;
      color: @secondary-text-color;

      .item-value {
        font-weight: 400;
      }
    }
  }

  .e-medical-data-entering-recommend {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    border-radius: 0 0 3 * @border-radius-base 3 * @border-radius-base;
    padding: 0 16px 16px 16px;
    flex-wrap: wrap;

    .title {
      font-size: @font-size-md;
      color: @secondary-text-color;
      margin: 20px 0 20px @margin-base;
    }

    .item {
      min-width: 100px;
      padding: 0 16px;
      margin-top: 16px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      background: #f0f2f5;
      font-size: @font-size-md;
      color: @primary-text-color;
      border-radius: @border-radius-base;
      cursor: pointer;
      // margin: 0 @margin-2;

      + .item {
        margin-left: @margin-base;
      }
    }

    .disabled-item {
      cursor: not-allowed;
    }
  }
}
.diagnosis {
  .ant-modal-content {
    .container {
      height: 300px !important;
    }
  }
  .item {
    width: auto !important;
    height: auto !important;
    min-width: 238px;
    min-height: 36px;
    line-height: 36px;
    text-indent: 16px;
    color: #333333;
    font-size: 16px;
    background-color: #f6f8fa;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid transparent;
    margin-bottom: 12px;
    display: inline-block;
  }
}
.diagnosis-modal {

}

.e-medical-data-entering-modal {
  user-select: none;

  .ant-modal-body {
    padding: 0 !important;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 592px;
  }

  .left {
    width: 778.5px;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .main {
      flex: 1;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .title {
        font-size: 18px;
        color: #333;
        font-weight: bold;
        margin: 28px 0 16px 0;
      }

      .content {
        // flex: 1;
        overflow-y: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        .item {
          width: 238px;
          height: 36px;
          line-height: 36px;
          text-indent: @padding-base;
          color: #333333;
          font-size: @font-size-md;
          background-color: @grey-color;
          border-radius: @border-radius-base;
          cursor: pointer;
          border: 1px solid transparent;
          margin-bottom: 12px;
          display: inline-block;

          // &:nth-of-type(3n+2) {
          //     margin-left: 16px;
          // }

          &:nth-of-type(3n + 2) {
            margin-left: 12px;
          }

          &:nth-of-type(3n + 3) {
            margin-left: 12px;
          }
        }

        .disabled-item {
          border-color: transparent;
          cursor: not-allowed;
        }
      }
    }
  }

  .right {
    width: 302.5px;
    height: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.06);
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .title {
      font-size: 18px;
      color: #333;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .tab-list {
      .ant-btn {
        border-radius: 20px;
        height: 32px;
        padding: 0 12px;

        + .ant-btn {
          margin-left: 8px;
        }
      }

      .active {
        background-color: #ff60a3;
        border-color: #ff60a3;
        color: #fff;
      }
    }

    .container-item {
      flex: 1;
      width: 100%;
      overflow-y: auto;
      margin-top: 16px;

      .item {
        width: 100%;
        height: 70px;
        border-radius: 4px;
        background-color: #f6f8fa;
        padding: 12px 16px;
        cursor: pointer;

        + .item {
          margin-top: 16px;
        }

        .label {
          color: #333;
          font-size: 16px;
        }

        .info {
          color: #666;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 4px;

          span {
            + span {
              margin-left: 6px;
            }
          }
        }
      }
    }
  }

  .single {
    width: 100%;

    .main {
      .content {
        .item {
          width: 248px;

          &:nth-of-type(3n + 2) {
            margin-left: 0;
          }

          &:nth-of-type(3n + 3) {
            margin-left: 0;
          }

          &:nth-of-type(4n + 2) {
            margin-left: 16px;
          }

          &:nth-of-type(4n + 3) {
            margin-left: 16px;
          }

          &:nth-of-type(4n + 4) {
            margin-left: 16px;
          }
        }

        .active {
          border-color: @primary-color;
          background-color: #ffe3f4;
          color: @primary-color;
        }

        .disabled-item {
          border-color: transparent;
          cursor: not-allowed;
        }
      }
    }
  }

  .ant-modal-body {
    padding: @padding-base;
  }
}

.e-medical-data-entering-active-item {
  border-color: @primary-color !important;
  background-color: #ffe3f4 !important;
  color: @primary-color !important;
}

@root-entry-name: default;