@import '../../style/vars.less';

.e-medical-form-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  min-height: 100%;

  > .content {
    flex: 1;
    width: 100%;
    .ant-form-item {
      margin-bottom: 4px !important;
    }
  }
}

.e-medical-form-submit {
  width: 100%;
  // position: absolute;
  left: 0;
  bottom: 0;
  height: 52px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0 0 @border-radius-base @border-radius-base;
  padding: 0 @padding-base;
}

.ant-input-suffix {
  .ant-image {
    display: flex !important;
  }
}

.import-history-image {
  width: 20px !important;
  cursor: pointer;
}

.import-image {
  width: 24px !important;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
}

.e-medical-hpi-modal {
  .empty-hpi {
    height: 200px;
    line-height: 200px;
    text-align: center;
    font-size: 20px;
  }

  .container {
    max-height: calc(~'100vh - 300px');
    overflow-y: auto;

    .hpi-content-block {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;

      .date {
        font-size: @font-size-md;
        color: #333;
        white-space: nowrap;
        margin-top: 24px;
        margin-right: 15px;
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 4px;
        background: rgba(255, 239, 248, 0.6);
        padding: 8px;
        flex: 1;

        .header {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-size: @font-size-md;
          color: #666;
          margin-bottom: 12px;
        }

        .footer {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-size: @font-size-md;
          color: #666;
          margin-top: 12px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .delete-hpi {
            height: 20px !important;
            cursor: pointer;
          }

          > div {
            margin-left: 6px;
          }
        }

        .text {
          font-size: @font-size-md;
          color: #333;
          width: 100%;
        }
      }

      + .hpi-content-block {
        margin-top: @margin-base;
      }
    }
  }
}

@root-entry-name: default;