@import '../../style/vars.less';

.e-medical-page-block {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px 0px #DDDDDD;
    border-radius: @border-radius-base-lg;
    display: flex;
    flex-direction: column;

    >.title {
        width: 100%;
        height: 56px;
        min-height: 56px;
        max-height: 56px;
        background: #FFFFFF;
        border-radius: @border-radius-base-lg @border-radius-base-lg 0px 0px;
        opacity: 1;
        display: flex;
        align-items: center;
        padding-left: 18px;
        border-bottom: 1px solid #D8D8D8;
    }

    >.main {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex: 1;
        height: 0;

        >.container {
            width: 0;
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;

            >.content {
                // height: calc(~"100vh - 234px");
                flex: 1;
                height: 0;
                width: 100%;
                padding: @padding-base + @padding-2 @padding-base;
                overflow: auto;
                border-radius: 0 0 @border-radius-base-lg @border-radius-base-lg;
                position: relative;
            }

            >.header {
                padding: @padding-base 26px 0 @padding-base;
            }
        }

        .e-medical-anchor-block {
            border-radius: 0 0 @border-radius-base-lg 0;

            .content {
                border-radius: 0 0 @border-radius-base-lg 0;
            }
        }
    }
}

.e-medical-spin {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
@root-entry-name: default;