@import '../../../../style/vars.less';

.e-medical-record-detail-container {
    .ant-tabs-tabpane {
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }
}

.pregnancy-echars {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.e-medical-record-medical {
    height: 100%;

    .detail-content {
        height: 0;

        .ant-pro-table .ant-pro-table-search {
            margin-bottom: 0 !important;
        }
    }

    .medical-detail-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;

        .e-medical-form-block {
            flex: 1;
            padding: 24px 24px 24px 0;
        }
    }

    .medical-list-block {
        width: 456px;
        height: 100%;
        padding: 0 20px 24px 20px;
        overflow-y: auto;

        .block {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .block-label {
                color: #333;
                font-size: @font-size-lg;
                position: relative;
                margin: 24px 12px 16px 12px;

                &:before {
                    content: "";
                    width: 3px;
                    height: 14px;
                    background-color: @primary-color;
                    position: absolute;
                    left: -12px;
                    top: 8px;
                    border-radius: 3px;
                }
            }

            .item {
                width: 100%;
                height: 48px;
                border: 1px solid #c9c9c9;
                border-radius: 4px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 12px 0 16px;
                cursor: pointer;

                +.item {
                    margin-top: @margin-base;
                }
            }

            .active-item {
                border: 1px solid #F24F86;
                box-shadow: 0px 4px 15px 0px rgba(242, 79, 134, 0.16);
            }

            .item-label {
                font-size: @font-size-md;
                color: #000;
            }

            .item-time {
                font-size: @font-size-md;
                color: #3D3D3D;
            }
        }
    }

    .e-medical-form-block {
        overflow-y: auto;
    }

    .e-medical-record-detail-footer {
        background-color: #fff;
    }
}

.e-medical-record-examination {
    .detail-content {
        .ant-pro-table .ant-pro-table-search {
            margin-bottom: 0 !important;
        }
    }
}

.gravida-record-container {
    padding: 0 !important;
    display: flex;

    .detail-content-nopadding {
        padding: 0 !important;
        height: 0 !important;
        flex: 1;
    }
}

.e-medical-drawer-nopadding {
    .ant-drawer-body {
        padding: 0;
    }
}

.record-info-main {
    .ant-tabs-nav-list {
        margin-left: 32px;
    }
}

.fuh-20 {
    position: absolute;
    top: 7px;
    right: 0;
    height: 32px;
    background-color: #FF60A3;
    color: #fff;
    text-align: center;
    line-height: 32px;
    border-radius: 2px;
    padding: 0 12px;
    z-index: 99;
    cursor: pointer;
}

.e-medical-record-fuh20 {
    border: 1px solid #d9d9d9;

    >div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 32px;
        width: 100px;

        &:not(:first-of-type) {
            border-top: 1px solid #d9d9d9;
        }

        >div {
            width: 50%;
            height: 100%;
            text-align: center;
            line-height: 32px;

            &:first-of-type {
                border-right: 1px solid #d9d9d9;
            }
        }
    }
}
@root-entry-name: default;