@import '../../style/vars.less';

.e-medical-recommend-block {
    width: 100%;
    // background-color: @grey-color;
    border-radius: 12px;
    margin-top: 20px;

    .block {
        width: 100%;
        // padding: 20px @padding-base;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        // align-items: stretch;
    }

    .item {
        // width: calc(~"33.33% - 5.5px");
        width: 100%;
        margin-top: 16px;
        background-color: #fff;
        padding: @padding-base 20px;
        border-radius: 12px;
        border: 1px solid #FFEFF8;

        +.e-medical-page-block {
            margin-left: 11px;
        }

        .title {
            margin-bottom: @margin-base;
            font-size: @font-size-lg;
        }

        >.content {
            font-size: @font-size-md;
            font-weight: 300;
            min-height: 80px;

            >div {
                +div {
                    margin-top: 8px;
                }
            }
        }
    }
}
@root-entry-name: default;