@import '../../style/vars.less';

.e-medical-form-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  min-height: 100%;

  > .content {
    flex: 1;
  }
}

.e-medical-form-submit {
  width: 100%;
  // position: absolute;
  left: 0;
  bottom: 0;
  height: 52px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0 0 @border-radius-base @border-radius-base;
  padding: 0 @padding-base;
}

.e-medical-nurse-login {
  width: 100%;
  height: 100vh;
  background-color: rgb(250, 239, 246);
  overflow: hidden;

  .ant-input-affix-wrapper {
    height: 44px;
  }

  > .ant-image {
    position: absolute;
    left: 77px;
    top: 0;
    height: 100%;

    .login-img-1 {
      height: 100%;
    }
  }

  .login {
    height: 100%;
    width: 760px;
    border-radius: 32px 0 0 32px;
    background-color: #fff;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    > .ant-image {
      position: absolute;
      top: 92px;
      left: -128px;
      width: 202px;
      height: 60px;
    }

    .block {
      width: 560px;
      height: 474px;
      background: rgba(255, 245, 249, 0.8);
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .ant-btn-primary[disabled] {
        background-color: rgb(254, 167, 203);
        border-color: rgb(254, 167, 203);
        color: #fff;
      }
    }

    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 60px;

      .login-img-3 {
        width: 336px;
      }

      .logo {
        height: 60px;
        width: 52px;
        margin-right: 12px;
      }
    }
  }
}

.e-medical-nurse-patient {
  display: flex;
  flex-direction: column;
  height: 100%;

  .top-search {
    width: 100%;
    padding: 24px 24px 0 24px;
    background-color: #fff;
  }

  .ant-form {
    display: flex;
    flex-wrap: wrap;

    .ant-form-item {
      margin-right: 32px;
    }
  }

  .main-table {
    width: 100%;
    flex: 1;
    height: 0;
    background-color: #fff;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .ant-tabs,
    .ant-tabs-nav {
      width: 100%;
      flex: 0;
    }

    .ant-tabs-nav-wrap {
      height: 50px;
      min-height: 50px;
      max-height: 50px;
      padding: 0 24px;
      width: 100%;
    }

    .ant-tabs-tab-active {
      font-size: 16px;
      font-weight: bold;
    }

    .patient-table {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .patient-operation {
      .ant-image {
        width: 32px;
        height: 32px;
        cursor: pointer;

        + .ant-image {
          margin-left: 16px;
        }
      }

      .patient-reception-ing {
        cursor: no-drop;
      }
    }
  }
}

@root-entry-name: default;