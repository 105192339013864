.e-medical-print {

    // 打印页面触发
    position: relative;

    @media Print {
        .top {
            height: 200px;
            margin-bottom: 20px;
        }

        .foot {
            height: 88px;
            margin-top: 20px;
        }

        .information {
            margin-top: -84px;
        }

        .printTitle {
            position: fixed;
            top: 0px;
        }

        .PrintFoot {
            position: fixed;
            bottom: 50px;
        }

    }

    width: 800px;
    box-sizing: border-box;
    padding: 0px 50px;
    background-color: #fff;

    .printTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
        width: 700px;
        color: #333333;
        // position: fixed;
        // top: 0px;
        border-bottom: 1px solid #000;

        .frequency {
            font-family: SourceHanSerifCN-Regular;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0em;
        }

        .title {
            margin: 0 78px 0 20px;
            font-family: SourceHanSerifCN-Bold;
            font-size: 32px;
            font-weight: bold;
            letter-spacing: 0em;

        }

        .code {
            display: flex;
            flex-direction: column;
            // margin: -16px 0 0 60px;
            position: absolute;
            right: 0px;

            img {
                width: 72px;
                height: 72px;
            }

            text {
                margin-top: 4px;
                font-family: STZhongsong;
                font-size: 12px;
                font-weight: normal;
                letter-spacing: 0em;
            }
        }

    }

    .PrintTop {
        margin-right: 16px;

        .information {
            .Columns {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 8px;

                .Column1 {
                    width: 210px;
                    font-family: SourceHanSerifCN-Medium;
                    font-size: 16px;
                    font-weight: 500;
                    color: #333333;

                    text {
                        color: #666666;
                    }
                }

                .Column2 {
                    width: 200px;
                    font-family: SourceHanSerifCN-Medium;
                    font-size: 16px;
                    font-weight: 500;
                    color: #333333;

                    text {
                        color: #666666;
                    }
                }

                .Column3 {
                    width: 200px;
                    font-family: SourceHanSerifCN-Medium;
                    font-size: 16px;
                    font-weight: 500;
                    color: #333333;

                    text {
                        color: #666666;
                    }
                }
            }
        }
    }

    .PrintMiddle {
        padding-top: 30px;
        box-sizing: border-box;
        font-family: SourceHanSerifCN-Medium;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0em;
        color: #333333;

        .title {
            width: 96px;
            text-align: right;
        }

        .title1 {
            width: 79px;
            text-align: right;
        }

        .content {
            flex: 1;
        }

        .chiefComplaint {
            margin: 0 0 11px 0px;
            display: flex;
            justify-content: flex-start;

        }

        .currentDisease {
            display: flex;
            justify-content: flex-start;
            color: #333333;
        }

        .epidemic {
            letter-spacing: 0em;

            /* 333 */
            color: #333333;
            margin: 10px 0 10px 96px;
        }

        .past {
            margin-bottom: 10px;

            .pasttitle {
                display: flex;
                justify-content: flex-start;
                margin-bottom: 10px;

                .pasttitle1 {
                    width: 80px;
                    text-align: left;
                }
            }

            .Subitem {
                margin: 0 0 10px 96px;
                display: flex;
                justify-content: flex-start;
            }
        }

        .physique {
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-start;
        }

        .Preliminary {
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-start;
        }

        .prescription {
            margin-bottom: 10px;

            .bold {
                font-weight: bold;
                margin-bottom: 10px;
                display: inline-block;
            }

            .list {
                min-width: 150px;
                margin: 0 32px 10px 0;
                font-size: 14px;
                font-weight: normal;
                line-height: 22px;
                letter-spacing: 0px;
                color: #333333;
            }

            .ChineseMedicine {
                margin-left: 96px;

                .name {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 17px;
                    letter-spacing: 0px;
                    color: #666666;
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: space-between;

                    .DrugName {
                        width: 300px;
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 17px;
                        letter-spacing: 0px;

                        color: #333333;
                    }
                }

                .pecifications {
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 14px;
                    // margin-right: 32px;
                }

                .use {
                    margin-right: 10px;
                }

                .spacing {
                    margin: 0 10px;
                }

                .spacing1 {
                    margin: 0 4px;
                }
            }

            .medicine {
                margin-left: 96px;

                div {
                    display: flex;
                    justify-content: space-between;

                    .medicineName {
                        width: 300px;
                    }
                }

            }
        }

        .inspect {
            margin-bottom: 20px;

            .doctorOrder {
                margin-left: 8px;
                padding-top: 8px;
            }
        }

        .test {
            margin-bottom: 20px;

            .doctorOrder {
                margin-left: 8px;
                padding-top: 8px;
            }
        }
    }

    .PrintFoot {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #000;
        width: 700px;
        height: 40px;


        .name {
            width: 200px;
        }
    }
}
@root-entry-name: default;