.e-medical-fetus-heart {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .ant-checkbox-wrapper {
        white-space: nowrap;
        margin-left: 8px;
    }
}
@root-entry-name: default;