@import '../../style/vars.less';

.e-medical-form-self-layout {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .ant-form-item+.ant-form-item {
        margin-left: 16px;
    }
}

.e-medical-form-layout-main {
    .title {
        height: 40px;
        background-color: @secondary-color;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        padding-left: 28px;
        margin-bottom: @margin-base;

        &::after {
            content: "";
            width: 4px;
            height: 40%;
            background: #FF60A3;
            position: absolute;
            left: 16px;
            border-radius: 12px;
        }

        >span {
            +span {
                margin-left: 20px;
            }
        }
    }

    .container {
        padding: 0 12px;
    }
}

.e-medical-form-layout-main-second {
    >.title {
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        margin-bottom: @margin-3;


        .word {
            font-weight: bold;
            font-size: @font-size-md;
            color: @primary-text-color;
        }

        >span {
            +span {
                margin-left: 20px;
            }
        }
    }
}

.e-medical-form-layout-block-main {
    display: flex;
}
@root-entry-name: default;