.woman-report {
  padding: 10px;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  background-color: #ffff;
  .report-header {
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
  }
  .patient-table {
    height: calc(100% - 110px);
    .table-actions {
      display: flex;
      gap: 16px;
      span {
        color: rgb(22, 119, 255);
        cursor: pointer;
      }
    }
  }
  .ant-form {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
}

@root-entry-name: default;